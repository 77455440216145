import React, { useState } from 'react';
import { Paper, Grid, IconButton } from '@mui/material';
import FormikSelectField from '../../Generic/FormikSelectField';
import InputField from '../../Generic/InputField';
import AssetImage from '../AssetImage';
import NumericInputField from '../../Generic/NumericInputField';
import HelpIcon from '@mui/icons-material/Help';
import theme from "../../../theme";
import ClassificationHelpModal from "./ClassificationHelpModal";
import FormikGearBox from "./FormikGearBox";

const defectLevels = [
    {
        "value": 0,
        "label": "Basic"
    },
    {
        "value": 1,
        "label": "Intermediate"
    },
    {
        "value": 2,
        "label": "Advanced"
    }
]

export default function VBTxConfigurationStep1({ formik, machineTypes, enums }) {
    const [open, setOpen] = useState(false);

    const helpButtonClick = () => {
        setOpen(true);
    }

    const handleModalClose = () => {
        setOpen(false);
    }

    const handleDriveTypeChange = (_) => {
        formik?.handleBlur({
            target: {
                name: 'driveType',
            },
        });
        if (formik?.values.driveType === 0) {
            formik?.setFieldValue('shaftSpeed', 1800);

            formik?.setFieldValue('p1AssignedNode.shaftSpeedSource', 1);
            formik?.setFieldValue('p2AssignedNode.shaftSpeedSource', 1);
            formik?.setFieldValue('p3AssignedNode.shaftSpeedSource', 1);
            formik?.setFieldValue('p4AssignedNode.shaftSpeedSource', 1);
            formik?.setFieldValue('disableShaftSpeedSource', true);
        } else {
            formik?.setFieldValue('p1AssignedNode.shaftSpeedSource', 2);
            formik?.setFieldValue('p2AssignedNode.shaftSpeedSource', 2);
            formik?.setFieldValue('p3AssignedNode.shaftSpeedSource', 2);
            formik?.setFieldValue('p4AssignedNode.shaftSpeedSource', 2);
            formik?.setFieldValue('shaftSpeedLoBound', 1500);
            formik?.setFieldValue('shaftSpeedHiBound', 3900);

            formik?.setFieldValue('disableShaftSpeedSource', false);
        }

    }

    const handleGearboxChange = (event) => {
        formik?.handleBlur({
            target: {
                name: 'gearboxLocation',
            },
        });
        if (event.target.value == 0) {
            formik?.setFieldValue('inputToothCount', 0);
            formik?.setFieldValue('outputToothCount', 0);
        } else {
            formik?.setFieldValue('bladeCount', 0);
        }

    }

    return (
        <Paper elevation={0} sx={{ position: 'relative', width: '75%', margin: 'auto' }}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowSpacing={2}
            >
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <InputField
                        name={'assetName'}
                        label={'Asset Name'}
                        placeholder={'Asset Name'}
                        onBlur={formik?.onBlur}
                        errors={formik?.errors['assetName']}
                        width={'100%'}
                    />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={'machineType'}
                        label={'Machine Type'}
                        data={machineTypes}
                        onBlur={formik?.onBlur}
                        errors={formik?.errors["machineType"]}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <AssetImage machineType={formik?.values.machineType} />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={'driveType'}
                        label={'Drive Type'}
                        data={enums.driveTypes}
                        onBlur={handleDriveTypeChange}
                        errors={formik?.errors["driveType"]}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>
                {(formik?.values.driveType == 0) && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <NumericInputField
                        name={'shaftSpeed'}
                        label={'Shaft Speed'}
                        placeholder={'Shaft Speed'}
                        onBlur={formik?.onBlur}
                        form={formik}
                        step={60}
                        errors={formik?.errors['shaftSpeed']}
                        width={'100%'}
                        digits={0}
                        preventempty="false"
                    />
                </Grid>}
                {(formik?.values.driveType != 0) && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <NumericInputField
                        name={'shaftSpeedLoBound'}
                        label={'Shaft Speed Lower Bound'}
                        placeholder={'Shaft Speed Lower Bound'}
                        form={formik}
                        step={60}
                        onBlur={formik?.onBlur}
                        errors={formik?.errors['shaftSpeedLoBound']}
                        width={'100%'}
                        digits={0}
                        preventempty="false"
                    />
                </Grid>}
                {(formik?.values.driveType != 0) && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <NumericInputField
                        name={'shaftSpeedHiBound'}
                        label={'Shaft Speed Upper Bound'}
                        placeholder={'Shaft Speed Upper Bound'}
                        form={formik}
                        step={60}
                        onBlur={formik?.onBlur}
                        errors={formik?.errors['shaftSpeedHiBound']}
                        width={'100%'}
                        digits={0}
                        preventempty="false"
                    />
                </Grid>}
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={'gearboxLocation'}
                        label={'Gearbox Location'}
                        data={enums.gearboxLocations}
                        onBlur={handleGearboxChange}
                        errors={formik?.errors["gearboxLocation"]}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>
                {(formik?.values.gearboxLocation == 0) && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <NumericInputField
                        name={'bladeCount'}
                        label={'Blade Count'}
                        placeholder={'Blade Count'}
                        form={formik}
                        step={1}
                        onBlur={formik?.onBlur}
                        errors={formik?.errors['bladeCount']}
                        width={'100%'}
                        digits={0}
                        preventempty="false"
                    />
                </Grid>}
                {formik?.values.gearboxLocation != 0 && formik?.values.gearboxLocation != null &&
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                        <FormikGearBox
                            formik={formik}
                        />
                    </Grid>}
                <Grid item xs={11} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={'defectLevel'}
                        label={'Desired Defect Classification Level'}
                        data={defectLevels}
                        onBlur={formik?.onBlur}
                        errors={formik?.errors["defectLevel"]}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>
                <Grid item xs={1} justifyContent="center" alignItems="center">
                    <IconButton onClick={helpButtonClick} sx={{ float: 'left', ml: 2, mt: 2, color: theme.palette.primary.main }}>
                        <HelpIcon fontSize={'large'} />
                    </IconButton>
                </Grid>
            </Grid>
            <ClassificationHelpModal
                open={open}
                handleModalClose={handleModalClose}
            />
        </Paper>
    )
}