import React, { useState, useEffect } from 'react';
import MUIDataTable, { ExpandButton } from "mui-datatables";
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import TableRow from '@mui/material/TableRow';
import { useTheme } from "@mui/material/styles";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useClasses } from '../../customHooks';
import AssetDashboardInnerTableExpandedRow from './AssetDashboardInnerTableExpandedRow';
import StatusTrafficLightDisplay from '../Generic/StatusTrafficLightDisplay';
import { GetSeverityStatusColorVIB } from '../Generic/MiscFunctions';
import CommunicationStatus from "../Generic/CommunicationStatus";
import BatteryStatus from "../Generic/BatteryStatus";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreMenu from '../Generic/MoreMenu';
import { Button } from '@mui/material';
import DefectSummaryButton from './DefectClassification/DefectSummaryButton';
import { useLocation, useNavigate } from 'react-router-dom';


const muiCache = createCache({
    key: 'mui-datatables',
    prepend: true,
});

const styles = {
    overrides: {
        MUIDataTableSelectCell: {
            expandDisabled: {
                // Soft hide the button.
                visibility: 'hidden',
            },
        },
    },
}

export default function AssetDashboardTableExpandable(props) {   
    const [statusValues, setStatusValues] = useState(null);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('Name');

    const classes = useClasses(styles);
    const theme = useTheme();

    useEffect(() => {
        const data = props.data.map((obj) => {
            const maxAlarmValue = Math.max(...obj.MaxAlarm.map((alarm) => alarm.MaxAlarmValue));
            const minSecondsSinceCheckIn = Math.min(...obj.CheckinInfo.map((checkIn) => checkIn.SecondsSinceCheckIn));
            const id = obj.ID;
            return {
                ID: id,
                MaxAlarmTypeID: maxAlarmValue,
                MinSeconds: minSecondsSinceCheckIn
            };
        });
        setStatusValues(data);
    }, [props.data]);

    const updateRowsExpanded = (allRowsExpanded) => {
        let myRowsExpanded = allRowsExpanded.map(item => { return item.dataIndex; });
        props.onRowExpansionChange(myRowsExpanded)
    }

    const updateRowsPerPage = (rowsPerPage) => {
        props.onChangeRowsPerPage(rowsPerPage);
    }

    const updateCurrentPage = (currentPage) => {
        props.onChangeCurrentPage(currentPage);
    }

    const updateSearchText = (currentSearchText) => {
        props.onChangeSearchText(currentSearchText);
        updateCurrentPage(0);
    }

    useEffect(() => {
        const savedOrder = localStorage.getItem('savedOrder');
        const savedOrderBy = localStorage.getItem('savedOrderBy');

        if (savedOrder) {
            setOrder(savedOrder);
        }

        if (savedOrderBy) {
            setOrderBy(savedOrderBy);
        }
    }, []);

    const navigate = useNavigate();
    const location = useLocation();

    const handleRoutes = (path) => {
        navigate(path, { state: { from: location.pathname } });
    }

    // Define columns for asset-level table
    const columns = [
        {
            name: "DisplayName",
            label: "Asset",
            options: {
                customSort: (data1, data2) => {
                    return data1.DisplayName.localeCompare(data2.DisplayName);
                },
                searchable: true,
                customBodyRenderLite: (dataIndex) => {
                    let obj = statusValues ? statusValues.find(x => x.ID === props.data[dataIndex].ID) : null;
                    let maxAlarmTypeID = obj ? obj.MaxAlarmTypeID : null;
                    let checkIn = obj ? obj.MinSeconds : null;

                    let status = GetSeverityStatusColorVIB(
                        maxAlarmTypeID,
                        props.alarmStatusInfo,
                        checkIn);
                    let vendorName = props.data[dataIndex].VendorName;

                    if (vendorName === 'Fiix') {
                        return (
                            <span style={{ color: status.StatusColor }}>
                                <strong>{props.data[dataIndex].DisplayName}</strong>
                                <img
                                    src={props.data[dataIndex].VendorIcon}
                                    alt={`Icon for ${vendorName}`}
                                    style={{ maxWidth: '5%', height: 'auto', verticalAlign: 'super', fontSize: 'smaller', position: 'relative', top: '-0.5em' }}
                                    //onError={() => handleImageError(vendorName)}
                                />
                            </span>
                        )
                    }
                    return (
                        <span style={{ color: status.StatusColor }}>
                            <strong>{props.data[dataIndex].DisplayName}</strong>
                        </span>
                    )
                }
            }
        },
        {
            name: "AssetStatus",
            label: "Status",
            options: {
                searchable: false,
                sort: false, 
                sortCompare: (order) => {
                    return (obj1, obj2) => {
                        //console.log(obj1);
                        //console.log(obj2.data.Severity);
                        let val1 = parseInt(obj1.data.Severity);
                        let val2 = parseInt(obj2.data.Severity);
                        return (val1 - val2) * (order === 'asc' ? 1 : -1);
                    };
                },                    
                customBodyRenderLite: (dataIndex) => {
                    let obj = statusValues ? statusValues.find(x => x.ID === props.data[dataIndex].ID) : null;
                    let maxAlarmTypeID = obj ? obj.MaxAlarmTypeID : null;
                    let checkIn = obj ? obj.MinSeconds : null;
                    let status = GetSeverityStatusColorVIB(
                        maxAlarmTypeID,
                        props.alarmStatusInfo,
                        checkIn);
                    return (
                        <StatusTrafficLightDisplay
                            Status={status}
                            alarmStatusInfo={props.alarmStatusInfo}
                            keyName={
                                dataIndex + "-" +
                                props.data[dataIndex].ID}
                        />
                    );
                }
            }

        },
        {
            name: "AssetWirelessSignal",
            label: "Connectivity",
            options: {
                searchable: false, 
                sort: false,
                customBodyRenderLite: (dataIndex) => {

                    const minCheckInTime = !props.data[dataIndex].CheckinInfo ? null :
                        Math.min(...props.data[dataIndex].CheckinInfo.map(item => item.SecondsSinceCheckIn));
                    const nodesAreDown = (minCheckInTime === null || minCheckInTime > 604800);
                    const thisAsset = props.data[dataIndex].Diagnostics;

                    const filteredData = thisAsset.filter(x => x.DataTypeDisplayName.includes('Signal Strength'));

                    if (!filteredData || filteredData.length === 0) {
                        return (
                            <React.Fragment>
                                <CommunicationStatus
                                    value={-200}
                                    unitType={"dBm"}
                                    checkinInfo={props.data[dataIndex].CheckinInfo}
                                    minCheckInTime={minCheckInTime}
                                />
                                {nodesAreDown && <PriorityHighIcon sx={{ color: theme.palette.error.main }} fontSize="large" />}
                            </React.Fragment>
                        );
                    } else {
                        const worstSignalStrength = Math.min(...filteredData.map(c => c.ComputedValue));
                        const unitTypeSymbol = filteredData.find(c => c.ComputedValue === worstSignalStrength).UnitTypeSymbol;

                        return (
                            <React.Fragment>
                                <CommunicationStatus
                                    value={worstSignalStrength}
                                    unitType={unitTypeSymbol}
                                    checkinInfo={props.data[dataIndex].CheckinInfo}
                                    minCheckInTime={minCheckInTime}
                                />
                                {nodesAreDown && <PriorityHighIcon sx={{ color: theme.palette.error.main }} fontSize="large" />}
                            </React.Fragment>
                        )
                    }
                }

            }
        },
        {
            name: "AssetBattery",
            label: "Battery",
            options: {
                searchable: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const minCheckInTime = !props.data[dataIndex].CheckinInfo ? null :
                        Math.min(...props.data[dataIndex].CheckinInfo.map(item => item.SecondsSinceCheckIn));

                    const thisAsset = props.data[dataIndex].Diagnostics;

                    const filteredData = thisAsset.filter(x => x.NodeChannelDisplayName === "Battery Voltage");

                    if (!filteredData || filteredData.length === 0) {

                        return (
                            <BatteryStatus
                                value={-1}
                                unitType={"V"}
                                minCheckInTime={minCheckInTime}
                            />
                        );
                    } else {
                        const worstBattery = Math.min(...filteredData.map(c => c.ComputedValue));
                        const unitTypeSymbol = filteredData.find(c => c.ComputedValue === worstBattery).UnitTypeSymbol;

                        return (
                            <React.Fragment>
                                <BatteryStatus
                                    value={isFinite(worstBattery) ? worstBattery : -1}
                                    unitType={unitTypeSymbol}
                                    minCheckInTime={minCheckInTime}
                                />
                            </React.Fragment>
                        )
                    }
                }
            }
        },
        {
            name: "AssetMachineTypeDisplayName",
            label: "Machine Type",
            options: {
                searchable: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let opacity = (props.data[dataIndex].IsConnected ? 1 : 0.5);
                    return (
                        <span style={{ color: '#A9A9A9' + opacity }}>
                            <strong>{props.data[dataIndex].MachineType.DisplayName}</strong>
                        </span>
                    )
                }
            }
        },
        {
            name: "AssetDriveTypeDisplayName",
            label: "Drive Type",
            options: {
                searchable: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let opacity = (props.data[dataIndex].IsConnected ? 1 : 0.5);
                    return (
                        <span style={{ color: '#A9A9A9' + opacity }}>
                            <strong>{props.data[dataIndex].DriveType.DisplayName}</strong>
                        </span>
                    )
                }
            }
        },
        {
            name: "ViewAsset",
            label: "View Asset",
            options: {
                sort: false,
                onClick: (e) => { e.stopPropagation(); e.preventDefault(); },
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <Button startIcon={<PreviewIcon />} onClick={() => {
                            handleRoutes(`/Vibration/AssetDetail/${props.companyID}/${props.userID}/${props.data[dataIndex].ID}/${props.viewAll}`);
                        }}
                        >
                            View
                        </Button>
                    );
                }
            }
        },
        {
            name: "ViewDefects",
            label: "View Defects",
            options: {
                sort: false,
                onClick: (e) => { e.stopPropagation(); e.preventDefault(); },
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <DefectSummaryButton
                            companyID={props.companyID}
                            userID={props.userID}
                            objectDefectSummary={props.data[dataIndex].ObjectDefectSummary} />
                    );
                }
            }
        },
        {
            name: "AssetMenu",
            label: "Menu",
            options: {
                sort: false,
                onClick: (e) => { e.stopPropagation(); e.preventDefault(); },
                customBodyRenderLite: (dataIndex) => {

                    let menuItems =
                        [
                            {
                                menuItemText: "Edit",
                                icon: <EditIcon />,
                                route: `/Vibration/EditAsset/${props.companyID}/${props.userID}/${props.data[dataIndex].ID}/${props.viewAll}`
                            },
                            {
                                menuItemText: "Clone",
                                icon: <ContentCopyIcon />,
                                route: `/Vibration/AddAsset/${props.companyID}/${props.userID}/${props.data[dataIndex].ID}/${props.viewAll}`
                            },
                            {
                                menuItemText: "Delete",
                                icon: <DeleteIcon />,
                                route: `/Vibration/EditAsset/${props.companyID}/${props.userID}/${props.data[dataIndex].ID}/${props.viewAll}`
                            },
                        ];
                    let menuKey = "objectID-" + props.data[dataIndex].ID;

                    return (
                        <MoreMenu
                            menuKey={menuKey}
                            menuTitle={"More..."}
                            buttonIcon={<MoreHorizIcon />}
                            menuItems={menuItems}
                        />
                    );
                }
            }
        },
        {
            name: "ID",
            options: {
                display: "excluded"
            }
        },
        {
            name: "NodeNames",
            options: {
                display: "excluded",
                searchable: true
            }
        }

    ];


    // Define options for asset-level table
    const options = {
        elevation: 5,
        filter: false,
        download: false,
        print: false,
        selectableRows: 'none',
        responsive: 'standard',
        enableNestedDataAccess: ".",
        page: props.dashboardPage,
        onChangePage: (currentPage) => { updateCurrentPage(currentPage); },
        rowsPerPage: props.dashboardRowsPerPage,
        onChangeRowsPerPage: (numberOfRows) => { updateRowsPerPage(numberOfRows); },
        sortOrder: {
            name: orderBy,
            direction: order,
        },
        onColumnSortChange: (changedColumn, direction) => {
            setOrder(direction);
            setOrderBy(changedColumn);
            localStorage.setItem('savedOrder', direction);
            localStorage.setItem('savedOrderBy', changedColumn);
        },
        count: props.data.count,
        selectableRowsHideCheckboxes: true,
        viewColumns: true,
        onSearchChange: (searchText) => { updateSearchText(searchText); }, 
        searchText: props.searchText,
        searchPlaceholder: 'Search',
        searchAlwaysOpen: props.searchAlwaysOpen,
        customSearch: (searchQuery, currentRow, columns) => {
            let isFound = false;
            currentRow.forEach((col, index) => {
                if (['AssetDisplayName', 'AssetMachineTypeDisplayName', 'AssetDriveTypeDisplayName', 'NodeNames'].includes(columns[index].name)) {
                    if (col && col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                        isFound = true;
                    }
                }
            });

            
            return isFound;
        },

        rowsExpanded: props.dashboardRowsExpanded,
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) => {
            updateRowsExpanded(allRowsExpanded);
        },
        renderExpandableRow: (rowData, rowMeta) => {

            let colspan = columns.length;
            let asset = props.data.find(n => n.ID === rowData[7]);
            let myFullNodes = [];

            if (asset && asset.Nodes) {
                myFullNodes = asset.Nodes.filter(function (n) {
                    return ((typeof n != "object" || Object.keys(n).length > 0) &&
                        (n.DisplayName.toLowerCase().includes(props.searchText.toLowerCase()))
                        || asset.DisplayName.toLowerCase().includes(props.searchText.toLowerCase())                    );
                });
            } else {
                return [];
            }


            //console.log(JSON.stringify(myFullNodes));

            return (
                <TableRow>
                    <AssetDashboardInnerTableExpandedRow
                        nodedata={myFullNodes}
                        colspan={colspan}
                        objectName={rowData[4]}
                        key={rowData[0]}
                        alarmStatusInfo={props.alarmStatusInfo}

                    />
                </TableRow>
            );

        },

    };

    const components = {
        ExpandButton: function (props) {
            return <ExpandButton {...props} />;
        },
    };


    return (
        <CacheProvider value={muiCache}>
            <MUIDataTable
                title={props.title}
                data={props.data}
                columns={columns}
                options={options}
                components={components}
                className={classes.overrides}
            />
        </CacheProvider>
    );
}