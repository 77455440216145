export const notificationTemplateFormModel = {
    formID: 'notificationTemplate',
    formField: {
        notificationName: {
            name: 'notificationName',
            label: 'Alarm Notification Name',
            requiredErrorMsg: 'Required',
            invalidErrorMsg: 'Alarm Notification Name can only be 255 characters long'
        },
        notificationSummary: {
            name: 'notificationSummary',
            label: 'Summary',
            requiredErrorMsg: 'Required',
            invalidErrorMsg: 'Summary can only be 1000 characters long'
        },
        actionSteps: {
            name: 'actionSteps',
            label: 'Recommended Actions',
        },
    }
}