import { notificationTemplateFormModel } from './NotificationTemplateFormModel';
import * as Yup from 'yup';

const {
    formField: {
        notificationName,
        notificationSummary,
        actionSteps
    }
} = notificationTemplateFormModel;

export const notificationTemplateValidationSchema = Yup.object().shape({
    [notificationName.name]: Yup.string()
        .max(255, [notificationName.invalidErrorMsg])
        .required([notificationName.requiredErrorMsg]),
    [notificationSummary.name]: Yup.string()
        .max(255, [notificationSummary.invalidErrorMsg])
        .required([notificationSummary.requiredErrorMsg]),
    [actionSteps.name]: Yup.array().of(
        Yup.object().shape({
            'actionStepSummary': Yup.string().required('Required')
        })
    )
})