import React from 'react';
import { ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useLocation, useNavigate } from 'react-router-dom';
import DefectSummaryButton from "../Vibration/DefectClassification/DefectSummaryButton";



export default function MoreMenu(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const handleRoutes = (path) => {
        navigate(path, { state: { from: location.pathname } });
    }

    return (
        <PopupState variant="popover" popupId={`popup-menu-${props.menuKey}`}>
            {(popupState) => (
                <React.Fragment>
                    <IconButton
                        {...bindTrigger(popupState)}
                        size="large">
                        {props.buttonIcon}
                    </IconButton>
                    
                    <Menu {...bindMenu(popupState)}>
                        {props.menuItems.map((item, index) => {
                            return (
                                <MenuItem
                                    onClick={(e) => {
                                        if (props.showToast && item.menuItemText === "Delete") {
                                            e.preventDefault();
                                            props.onDeleteClick(item.ID, item.dataSourceID, props.onToastCloseFromMoreMenu);                                       
                                        }
                                        if (item.menuItemText === "SummaryButton") {
                                            //e.preventDefault();
                                            return <DefectSummaryButton
                                                companyID={item.companyID}
                                                userID={item.userID}
                                                objectDefectSummary={item.objectDefectSummary} />;
                                        }
                                        else {
                                            handleRoutes(item.route);
                                        }
                                        popupState.close();
                                    }}
                                    key={`menu-item-${props.menuKey}-${item.menuItemText}-${index}`}
                                >
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText>{item.menuItemText}</ListItemText>
                                </MenuItem>
                            );
                        })
                        }
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    );

}

















