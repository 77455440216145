import { notificationTemplateFormModel } from './NotificationTemplateFormModel'

const {
    formField: {
        notificationName,
        notificationSummary,
        actionSteps
    }
} = notificationTemplateFormModel;

export const notificationTemplateInitialValues = {
    [notificationName.name]: '',
    [notificationSummary.name]: '',
    [actionSteps.name]: [{
        'actionStepSummary': '',
        'actionStepPhoto': ''
    }]
}