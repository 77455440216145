import React from 'react';
import {
    IconButton, Typography, Box
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CloseIcon from '@mui/icons-material/Close';
export default function NotificationAttachment({ formik, index }) {

    const handleFileChange = (event, formik, index) => {
        const file = event.target.files[0];
        if (file) {
            formik.setFieldValue(`actionSteps.${index}.actionStepFileURL`, URL.createObjectURL(file))
            formik.setFieldValue(`actionSteps.${index}.actionStepFile`, file)
        }
    };

    const handleRemoveFile = () => {
        formik.setFieldValue(`actionSteps.${index}.actionStepFileURL`, '')
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid gray',
                mx: 2,
                height: '100%'
            }}
        >
            {/* Upload Button */}
            {!formik.values.actionSteps[index].actionStepFileURL && <Box
                component="label"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    border: '1px dashed gray',
                    padding: 2,
                    borderRadius: 1,
                    cursor: 'pointer',
                }}
            >
                <input
                    type="file"
                    accept="image/*"
                    capture="environment"
                    style={{ display: 'none' }}
                    id={`action-image-${index}`}
                    onChange={(event) => { handleFileChange(event, formik, index) }}
                />
                <AddAPhotoIcon />
                <Typography>Add Photo</Typography>
            </Box>}

            {/* Image Preview */}
            {formik.values.actionSteps[index].actionStepFileURL && (
                <Box>
                    <img
                        src={formik.values.actionSteps[index].actionStepFileURL}
                        alt="Selected"
                        data-id={index}
                        style={{ maxWidth: '100%', maxHeight: 140, borderRadius: 8 }}
                    />
                </Box>
            )}

            {/* Remove Button */}
            {formik.values.actionSteps[index].actionStepFileURL && (
                <IconButton
                    color="error"
                    onClick={handleRemoveFile}
                    title="Remove attachment"
                >
                    <CloseIcon />
                </IconButton>
            )}
        </Box>
    )
}