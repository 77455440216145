import React, { useState } from 'react';
import {
    Paper, Modal, Button, IconButton, Typography, Divider, Grid, Stack, Tooltip, Box
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import HelpIcon from '@mui/icons-material/Help';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Form, Formik } from 'formik';
import { notificationTemplateInitialValues } from '../Models/NotificationTemplateInitialValues';
import { notificationTemplateValidationSchema } from '../Models/NotificationTemplateValidationSchema';
import { notificationTemplateFormModel } from '../Models/NotificationTemplateFormModel';
import InputField from '../../Generic/InputField';
import MultilineInputField from '../../Generic/MultilineInputField';
import { ConvertNotificationTemplateValues, createFileFormData } from './AlarmNotificationTemplateHelpers'
import NotificationAttachment from './NotificationAttachment';
import { postNotificationTemplate, updateNotificationTemplate } from './AlarmNotificationTemplateQueryFunctions';
import { useMutation } from "react-query";
import { createToastError, createToastSuccess } from "../../Generic/MiscFunctions";

const { formId } = notificationTemplateFormModel;

const TOAST_SUCCESS_LIFE = 3000;
const TOAST_ERROR_LIFE = 30000;

export default function AlarmNotificationTemplateModal({ open, template, handleClose, setErrorState, toast, companyID, setLoadTemplates }) {

    const doPostTemplate =
        useMutation((template) => postNotificationTemplate(template), {
            onSuccess: (_) => {
                handleClose();
                toast.current.show(createToastSuccess('Notification Template Successfully Created', TOAST_SUCCESS_LIFE));
                setLoadTemplates(true);
            },
            onError: (_) => {
                handleClose();
                toast.current.show(createToastError('An error occurred while attempting to upload attachment.', TOAST_ERROR_LIFE));
            }
        });

    const doUpdateTemplate =
        useMutation((template) => updateNotificationTemplate(template), {
            onSuccess: (_) => {
                handleClose();
                toast.current.show(createToastSuccess('Notification Template Successfully Updated', TOAST_SUCCESS_LIFE));
                setLoadTemplates(true);
            },
            onError: (_) => {
                handleClose();
                toast.current.show(createToastError('An error occurred while attempting to upload attachment.', TOAST_ERROR_LIFE));
            }
        });

    const handleFormikSubmit = async (values) => {
        let formData = createFileFormData(template, values, companyID);
        if (template) {
            doUpdateTemplate.mutateAsync(formData);
        } else {
            doPostTemplate.mutateAsync(formData);
        }
    }

    const handleAddStep = (formik) => {
        formik.setFieldValue(`actionSteps`, [...formik.values.actionSteps, {
            'actionStepSummary': '',
            'actionStepPhoto': ''
        }])
    }

    const handleRemoveStep = (formik, index) => {
        formik.setFieldValue(`actionSteps`, formik.values.actionSteps.filter((_, arrIndex) => {
            return index !== arrIndex;
        }));
    }

    return (
        <Modal
            open={open}
        >
            <Paper className="modal-paper-long">
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={8}>
                        <Typography variant="h5">{template ? 'Edit Alarm Notification' : 'Create New Alarm Notification'}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <IconButton sx={{ float: "right" }} onClick={handleClose}>
                            <ClearIcon fontSize={"large"} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                <br />
                <Formik
                    initialValues={template ? ConvertNotificationTemplateValues(template) : notificationTemplateInitialValues}
                    validationSchema={notificationTemplateValidationSchema}
                    onSubmit={handleFormikSubmit}
                >
                    {(formik) => (
                        <Form id={formId}>
                            <div>
                                <div>
                                    <Stack spacing={2} alignItems='center'>
                                        <InputField
                                            name={'notificationName'}
                                            label={'Alarm Notification Name'}
                                            placeholder={'Alarm Notification Name'}
                                            onBlur={formik?.onBlur}
                                            errors={formik?.errors['notificationName']}
                                            width={'100%'}
                                        />
                                        <MultilineInputField
                                            name={'notificationSummary'}
                                            label={'Summary'}
                                            placeholder={'Summary'}
                                            onBlur={formik?.onBlur}
                                            errors={formik?.errors['notificationSummary']}
                                            width={'100%'}
                                        />
                                        <Grid container>
                                            <Grid item xs={11}>
                                                <Typography variant="h5">Recommended Actions</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Tooltip title={<Typography>The overview and action steps entered below will be delivered
                                                    to alarm notification recipients when an alarm is
                                                    triggered</Typography>} placement="right">
                                                    <IconButton>
                                                        <HelpIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        {formik?.values.actionSteps.map((_, index) => {
                                            return (
                                                <Grid container key={index}>
                                                    <Grid item xs={11}>
                                                        <Typography variant="h6">{`Action Step ${index + 1}`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <IconButton
                                                            color="error"
                                                            sx={{ margin: 'auto' }}
                                                            onClick={() => { handleRemoveStep(formik, index) }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <MultilineInputField
                                                            name={`actionSteps.${index}.actionStepSummary`}
                                                            label={''}
                                                            placeholder={'Default Action Step'}
                                                            onBlur={formik?.onBlur}
                                                            errors={formik?.errors[`actionSteps.${index}.actionStepSummary`]}
                                                            width={'100%'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <NotificationAttachment
                                                            formik={formik}
                                                            index={index}
                                                            setErrorState={setErrorState}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                        <Box>
                                            <Button
                                                variant="contained"
                                                endIcon={<AddCircleIcon />}
                                                color="success"
                                                onClick={() => { handleAddStep(formik) }}
                                            >
                                                Add Step
                                            </Button>
                                        </Box>
                                    </Stack>
                                    <br />
                                    <Grid container direction="row">
                                        <Grid item xs={6}>
                                            <Button variant="contained" style={{ float: "left" }} onClick={handleClose}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button variant="contained" style={{ float: "right" }} type="submit">
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Paper>
        </Modal>
    )
}