import { notificationTemplateFormModel } from '../Models/NotificationTemplateFormModel'

const {
    formField: {
        notificationName,
        notificationSummary,
        actionSteps
    }
} = notificationTemplateFormModel;

export const ConvertNotificationTemplateValues = (template) => {
    return {
        [notificationName.name]: template.AlarmNotificationDisplayName,
        [notificationSummary.name]: template.AlarmNotificationSummary,
        [actionSteps.name]: template.AlarmNotificationActionStepsList.map((step) => {
            let file = null;
            if (step.AttachmentFile) {
                const base64Data = step.AttachmentFile.split(',')[1];
                const byteCharacters = atob(base64Data);
                const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
                const byteArray = new Uint8Array(byteNumbers);
                file = new File([byteArray], step.AttachmentFileName, { type: 'image/' + step.AttachmentFileType });
            }
            return {
                'actionStepSummary': step.ActionStepSummary,
                'actionStepFileURL': file ? URL.createObjectURL(file) : null,
                'actionStepFile': file
            }
        })
    }
}

export const createFileFormData = (template, formikValues, companyID) => {
    const formData = new FormData();

    let actionSteps = formikValues.actionSteps.map((step, index) => {
        let file = step.actionStepFile;
        let fileType = null;
        if (file) {
            fileType = file.type.split('/')[1];
            formData.append('files', file);
        }
        return {
            ActionStepID: null,
            AlarmNotificationID: null,
            Ordering: index + 1,
            ActionStepSummary: step.actionStepSummary,
            AttachmentFileName: file ? file.name : null,
            AttachmentFileType: fileType,
        }
    })

    const alarmTemplate = {
        AlarmNotificationID: template ? template.AlarmNotificationID : null,
        CompanyID: Number(companyID),
        AlarmNotificationDisplayName: formikValues.notificationName,
        AlarmNotificationSummary: formikValues.notificationSummary,
        IsTemplate: true,
        TemplateNotificationID: template ? template.TemplateNotificationID : null,
        AlarmNotificationActionStepsList: actionSteps 
    }

    formData.append("metadata", JSON.stringify(alarmTemplate));

    return formData;

}